/**
 * Smooth Scroll Utility
 */

/**
 * SCroll to the anchors function
 * @param {*} e The event from the click
 * @param {*} respond The response from the click
 * @returns
 */
((window, document) => {
  function scrollAnchors(e, respond = null) {
    let targetID;
    const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
    // If the target isn't an anchor tag, get the custom data attribute tag
    if (e.target.tagName !== 'A' && this.getAttribute('data-smooth-scroll-target')) {
      targetID = respond
        ? respond.getAttribute('data-smooth-scroll-target')
        : this.getAttribute('data-smooth-scroll-target');
    } else {
      e.preventDefault();
      targetID = respond ? respond.getAttribute('href') : this.getAttribute('href');
    }
    const targetAnchor = document.querySelector(targetID);
    if (!targetAnchor) return;
    const originalTop = distanceToTop(targetAnchor) - 140;
    window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
    const checkIfDone = setInterval(() => {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = '-1';
        window.history.pushState('', '', targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }

  /**
   * Get all the smooth scroll links and loop through to
   * assign click events to each one
   */
  const scrollToTarget = () => {
    const links = document.querySelectorAll('.js-smooth-scroll-link');
    links.forEach((each) => {
      each.onclick = scrollAnchors;
    });
  };
  vm.onload(scrollToTarget);
})(window, document);

/*
(() => {
  scrollToTarget();
})(); */
